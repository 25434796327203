.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #0288D1;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  margin-top: 20px;
  width: 100%;
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px
}

.thumbs {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 200px;
  height: auto;
  padding: 4px;
  box-sizing: border-box;

  img {
    display: block;
    width: auto;
    height: 100%
  }
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

@media only screen and (max-width: 576px) {
  .dropzone {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .dropzone {
    width: 100%;
  }
}