.dropdown-btn {
  position: relative;
  .btn-success {
    color: #424242;
    background-color: #ffffff;
    border-color: #1e7e34;
    box-shadow: none;
  }
  .btn-success:hover {
    color: #424242;
    background-color: #ffffff;
  }
  .btn-success:focus {
    color: #424242 !important;
    background-color: #ffffff;
    box-shadow: none;
  }
  .btn-success:active {
    color: #424242 !important;
    background-color: #ffffff;
    box-shadow: none;
  }
  .btn-success:not(:disabled):not(.disabled).active,
  .btn-success:not(:disabled):not(.disabled):active,
  .show>.btn-success.dropdown-toggle {
    color:#424242;
    background-color:#ffffff;
    border-color:#1c7430
  }
}

@media only screen and (max-width: 576px) {
  .nav-item-text {
    .nav-link {
      display: block;
      padding: .5rem 0.5rem 0.5rem 0.5rem;
      font-size: 0.7rem;
    }
  }
  .dropdown-btn {
    .btn-success {
      font-size: 0.7rem;
    }
  }
}