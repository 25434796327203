.demo {
  padding: 3em 0;
}

.pricingTable {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 0 0 30px 30px;
  position: relative;
  z-index: 1;
}

.pricingTable:after {
  content: "";
  background: #00A897;
  width: 50%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.pricingTable .pricing-content {
  background-color: #fff;
  padding: 15px 0 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.pricingTable .pricingTable-header {
  padding: 20px 10px;
  margin: 0 0 5px;
}

.pricingTable .title {
  color: #333;
  font-size: 40px;
  font-weight: 300;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin: 0;
}

.pricingTable .content-list {
  padding: 0;
  margin: 0 0 27px;
  list-style: none;
  display: inline-block;
}

.pricingTable .content-list li {
  color: #333;
  font-size: 14px;
  text-transform: capitalize;
  text-align: left;
  //padding: 0 0 0 35px;
  margin: 0 0 10px;
  position: relative;
}

.pricingTable .content-list li:last-child {
  margin-bottom: 0;
}

.pricingTable .content-list li:before {
  //content: "\f00c";
  //color: #338806;
  ////font-family: "Font Awesome 5 Free";
  //font-size: 17px;
  //font-weight: 900;
  //position: absolute;
  //top: 0;
  //left: 0;
}

.icon-check {
  position: relative;
  color: #1e7e34;
  //stroke: #1e7e34;
  //stroke-width: 100%;
  right: 8px;
}

.icon-x {
  position: relative;
  color: #c82333;
  //stroke: #1e7e34;
  //stroke-width: 100%;
  right: 8px;
}

.pricing-title {
  position: relative;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 20px;
  h1 {
    color: #6f6f6f;
    font-weight: 600;
    font-size: 2rem;
  }
}

.conditions-text {
  position: relative;
  p {
    font-size: 0.7rem;
    margin-left: 15px;
  }
}

.pricingTable .content-list li.disable:before {
  content: "\f00d";
  color: #FF3631;
}

.pricingTable .price-value {
  margin: 0 0 20px;
}

.pricingTable .price-value .amount {
  font-size: 35px;
  font-weight: 500;
  line-height: 40px;
  margin: 0 0 5px;
  display: block;
}

.pricingTable .price-value .duration {
  font-size: 16px;
  display: block;
}

.pricingTable .price-value.top-pricing{
  .amount {
    font-size: 1.2em;
    font-weight: 600;
    //line-height: 40px;
    margin: 0 0 5px;
    display: inline;
    color: #4CAF50;
    padding-bottom: 10px;
  }
  .duration {
    font-size: .8em;
    font-weight: 500;
    //line-height: 40px;
    margin: 0 0 5px;
    display: inline;
    color: #4CAF50;
    padding-bottom: 10px;
  }
}


.pricingTable .pricingTable-signup a {
  color: #fff;
  background-color: #00A897;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 25px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s;
  text-decoration: none;
}

.pricingTable .pricingTable-signup a:hover {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
}

.pricingTable .pricingTable-signup a:before,
.pricingTable .pricingTable-signup a:after {
  content: '';
  background-color: rgba(0, 0, 0, 0.15);
  height: 100%;
  width: 50%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
  transition: all 0.3s ease;
}

.pricingTable .pricingTable-signup a:after {
  left: auto;
  right: 0;
}

.pricingTable .pricingTable-signup a:hover:before {
  opacity: 1;
  top: 50%;
}

.pricingTable .pricingTable-signup a:hover:after {
  opacity: 1;
  top: -50%;
}

.pricingTable.blue:after,
.pricingTable.blue .pricingTable-signup a {
  background-color: #0089BD;
}

.pricingTable.magenta:after,
.pricingTable.magenta .pricingTable-signup a {
  background-color: #C70134;
}

.discount-bar{
  background-color: #ff9f43;
  background-color: #ffa801;
  //background-color: #feca57;
  //background-color: #FF4100;

  .discount-title{
    padding: .6em 0;
    color: #ffffff;
    margin-bottom: 0;
    font-size: .9em;
    font-weight: 500;
    text-align: center;
  }

  .discount-link{
    padding: .2em 1.5em;
    background-color: #f5cd79;
    border-radius: 20px;
    color: #ffffff;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
    font-weight: bold;
    cursor: pointer;
  }

  .discount-link:hover {
    text-decoration: none;
    color: #227093;
  }
}

.plan-toggle{
  .btn {
    padding: 5px 15px !important;
    font-size: .9em !important;
    font-weight: 600;
  }
  .btn:first-child{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .btn:nth-child(2){
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
  .btn-primary.focus, .btn-primary:focus {
    border: none;
    box-shadow: none;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    border: none;
    box-shadow: none;
  }
}

@media only screen and (max-width: 990px) {
  .pricingTable {
    margin-bottom: 50px;
  }
}

