.faq-header {
  position: relative;
  //background-color: #FAFAFA;
  width: 100%;
  height: auto;
  //border-radius: 20%;
  background: rgb(250,250,250);
  background: linear-gradient(180deg, rgba(250,250,250,1) 0%, rgba(255,255,255,1) 100%);
  h1 {
    padding-top: 25px;
    padding-bottom: 25px;
    color: #616161;
    margin-top: 0;
  }
}

.card-bg {
  border: 1px solid #E0E0E0 !important;
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 0 21px -9px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 21px -9px rgba(0,0,0,0.1);
  box-shadow: 0 0 21px -9px rgba(0,0,0,0.1);
  border-radius: 8px !important;
  .card-header {
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EEEEEE;
    font-size: 1.2rem;
    font-weight: 600;
    color: #757575;
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .card-body {
    font-size: 1rem;
    color: #616161;
    padding-top: 20px;
    padding-bottom: 5px;
  }
}

.faq-item{
  .icon-arrow-down, .icon-arrow-up {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  .icon-arrow-down {
    color: #0984e3;
  }
}
