.dis-none {
  display: none;
}

.dis-block {
  display: block;
}

.payment-container {
  font-size: .9em;

  .payment-card {
    box-shadow: none;
    border-radius: 5px;
    margin-bottom: 4px;

    .card-header {
      padding: 12px 15px;
      //border-bottom: 2px solid $body-background-color;
      border-bottom: 2px solid var(body-background-color);
      font-weight: 500;
      background: #ffffff;
    }

    .card-title {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 1.1rem;
    }

    .card-body {
      padding: 15px;

      .title-text {
        font-size: 1em;
        margin-bottom: 0;
      }
      .login-link{
        padding: 0;
      }
    }
  }

  .payment-right {
    .card-title {
      padding: 0 10px;
    }

    .card-body {
      padding: 10px 25px;
    }
  }

  .billing-info {
    .form-group {
      margin-bottom: .1em;
    }

    .form-label {
      margin-bottom: 0;
      font-size: .8em;
    }

    .form-control {
      background-color: #ffffff;
      padding: 4px 10px;
      border: 1px solid #9E9E9E;
      font-size: .8em;
      border-radius: 3px;
      //font-family: $font-family-Montserrat;
    }

    .invalid-feedback {
      font-size: .6em;
    }

    //.form-control.is-invalid, .was-validated .form-control:invalid, .was-validated .form-control:valid, .form-control.is-valid,
    //.form-control.is-invalid, .was-validated .form-control:invalid {
    .form-control {
      background-position: calc(100% - 10px) calc(.375em + 0.15rem) !important;
    }

    .input-group-prepend {
      .input-group-text {
        border: 1px solid #9E9E9E;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        padding: 2px 10px;
      }
    }

    .prepended-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 2px solid #dddddd;
    }

    .password-append {
      .input-group-append {
        .input-group-text {
          background-color: #ffffff;
          border: 1px solid #9E9E9E;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          padding: 4px 10px;
          cursor: pointer;
        }
      }

      .append-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
    }

    .flag {
      width: 1.5em;
      height: 1em;
      display: flex;
      margin-right: 5px;
    }

    .password-group {
      margin-bottom: 0;
    }

    .strength-bars {
      line-height: .5em;
      margin-bottom: .2em;

      .strength-bar {
        display: inline-block;
        background-color: #9E9E9E;
        border-radius: 5px;
        height: 5px;
        width: 9%;
        margin-right: .3em;
      }
    }

    .form-check {
      margin-bottom: 0;
      font-size: .9em;

      .custom-control-label::before, .custom-control-label::after {
        top: .08rem;
      }
    }

    .select__clear-indicator {
      padding: 0 8px !important;
    }


  }

  .separater {
    width: calc(100% + 50px);
    margin-left: -25px;
    //border-bottom: 2px solid $body-background-color;
    border-bottom: 2px solid var(body-background-color);
  }

  .safe {
    font-size: .8em;
  }

  .credit-card {
    width: 480px;
    max-width: 100%;
    //min-width: 480px;
    //max-width: 480px;
  }

  .card-details {
    width: 480px;
    max-width: 100%;

    position: absolute;
    left: 30px;
    top: 45px;


    .form-group {
      margin-bottom: .3em;
    }

    .form-label {
      margin-bottom: 0;
      font-size: .6em;
      color: #616161;
      font-weight: 500;
    }

    .form-control {
      background-color: #ffffff;
      padding: 2px 10px;
      border: 1px solid #BDBDBD;
      font-size: .75em;
      font-weight: 600;
      border-radius: 3px;
      //font-family: $font-family-Montserrat;
    }

    .form-control {
      background-position: calc(100% - 10px) calc(.375em) !important;
    }

    .cvv {
      width: 60px;
      position: absolute;
      top: 15%;
      right: 7%;
    }

    select {
      display: inline-block;
      width: 75px;
      margin-left: 5px;
    }

    select.form-control:not([size]):not([multiple]) {
      height: 25px;
      font-size: .6em;
      padding-left: 5px;
      font-weight: 400;
    }

    .expiry-separate {
      display: inline-block;
      margin-left: 5px;
      color: #95a5a6;
      font-size: .7em;
      font-weight: 600;
    }

    .card-number {
      //width: 295px;
      width: 60%;
    }

    .card-holder {
      text-transform: uppercase;
    }
  }

  .address-title {
    font-size: 1.1rem;
    color: #424242;
  }

  .form-check {
    input, label {
      cursor: pointer;
      color: #424242 !important;
    }
  }

  .tour-title {
    color: #2d3436;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .sub-title {
    font-size: .6em;
    color: #636e72;
    margin-bottom: 5px;
  }

  .departure-date {
    font-size: .6em;
    color: #636e72;
    margin-bottom: 5px;
    font-weight: 500;
    display: inline-block;
    padding: 2px 0;

    //background-color: #74b9ff;
  }

  .offer-subscribe {
    .checkbox {
      label {
        font-size: .6em;
      }
    }

    .checkbox.checkbox-fill input[type="checkbox"] + .form-check-label::after,
    .checkbox.checkbox-fill input[type="checkbox"] + .cr::after {
      top: 14px;
    }

    .checkbox input[type="checkbox"] + .form-check-label::before,
    .checkbox input[type="checkbox"] + .cr::before {
      border-color: #b2bec3;
      margin-right: 10px;
      line-height: 14px;
      //font-size: 14px;
    }

    .checkbox input[type="checkbox"]:checked + .form-check-label::before,
    .checkbox input[type="checkbox"]:checked + .cr::before {
      border-width: 1px;
    }
  }

  .terms {
    .checkbox {
      label {
        color: #636e72;
      }
    }
  }

  .currency-mark {
    width: 1em;
    margin-right: 5px;
  }

  .order-summary {
    label {
      margin-bottom: 0;
    }

    .unit-price {
      font-size: .8em;
    }

    .small-cents {
      font-size: .8em;
    }

    .multiply {
      font-size: .9em;
      margin: 0 10px;
    }

    .pax {
      font-size: .8em;
    }

    .amount {
      color: #607D8B;
      font-weight: 600;
    }

    .months {
      font-size: .6rem;
      margin-bottom: 10px;
      line-height: .8em;
    }

    .small-text {
      font-size: .7em;
      margin-left: 10px;
    }

    .total-price {
      font-weight: 700;
      //color: $line-through-color;
    }

    .promo {
      font-size: .65em;
      color: #01579B;
      margin-bottom: 0;
      cursor: pointer;
      text-decoration: underline;
    }

    .promo-label{
      font-size: .7em;
      color: #004D40;
      font-weight: 500;
      display: inline;

    }
    .promo-control{
      margin: 0 15px;
      max-width: 100px;
      display: inline;
      border-color: #006064;
    }
    .add-promo{
      margin-top: .5em;
      padding: 5px 15px;
      display: inline;
    }
    .remove-promo{
      display: inline;
      font-size: .7em;
      margin-top: .7em;
      margin-bottom: 0;
      cursor: pointer;
      font-weight: 600;
      text-decoration: underline;
    }

    .card-total {
      font-size: .8em;
      margin-bottom: 0;

      .currency-mark {
        width: 1.4em;
        margin-right: 3px;
        margin-bottom: 4px;
      }

      .total-price {
        font-size: 1em;
        //color: $line-through-color;
      }
    }
  }

  .visa-warning {
    font-size: .65em;
    font-weight: 500;
    padding: 5px 15px;
  }

  .safe-invoice {
    width: 100%;
    height: auto;
    max-width: 70px;
  }

  .safe-msg {
    margin-top: .3em;
    font-size: .85em;
    color: #006064;
    font-weight: 500;
    margin-bottom: 0;
  }

}

.click-button {
  .btn-primary {
    //text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 4px 30px;
    margin-bottom: 0;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: 6px;
    float: right;
    right: 2rem;
    margin-right: 1rem;
    width: 100%;
    //background: rgb(96,163,188);
    //background: linear-gradient(180deg, rgba(96,163,188,1) 0%, rgba(74,105,189,1) 77%, rgba(74,105,189,1) 100%);
  }
  .btn-primary:hover {
    background-color: var(--primary-color);
    color: #ffffff;
    border-color: var(--primary-color);
  }
  .btn-primary:focus {
    background-color: var(--primary-color);
  }
}

.book-button {
  font-size: .9rem;
}

//Store

.delivery-charge-text {
  margin-left: 2.1rem;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.delivery-charge {
  display: flex;
  justify-content: flex-end;
  margin-right: 5.5rem;
}

.total-price {
  display: flex;
  justify-content: flex-end;
  margin-right: 5.5rem;
  color: #c82333;
  font-weight: bold;
}

.hr {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #dee2e6;
  width: 95%;
  margin: 0.6rem auto 0.6rem auto;
}

.checkout-text h1 {
  font-size: 1.3rem;
  font-weight: 600;
}

.shopping-cart-text h5 {
  font-size: 1rem;
  color: #212121;
  margin: 1rem;
}

.item-table{
  th:first-child {
    width: 1em;
  }

  td{
    padding: .2rem !important;
  }

  th:nth-child(2) {
    text-align: center;
    width: 12em;
  }

  th:nth-child(3) {
    text-align: left;
    min-width: 19em;
  }

  th:nth-child(4) {
    text-align: center;
    width: 10em;
  }

  th:nth-child(5) {
    text-align: center;
    width: 10em;
  }

  th:nth-child(6) {
    text-align: center;
    width: 12em;
  }

  th:nth-child(7) {
    width: 30px;
  }

  .small-column{
    font-weight: bold;
  }

  .text-bold{
    font-weight: 500;
    text-transform: capitalize;
  }
}

.qty-editor{
  i {
    font-size: 1.3em;
  }
  label{
    font-size: 1.2em;
    min-width: 1em;
    text-align: center;
  }
}


@media (max-width: 768px) {
  .payment-container {
    .safe-msg {
      margin-top: .3em;
      font-size: .7em;
    }

    .credit-card {
      height: 110%;

    }

    .card-details {
      .cvv {
        width: 50px;
        top: 27%;
        right: 10%;
      }
    }
  }
}

@media (max-width: 992px) {
  .payment-container {
    .payment-card {
      .card-title {
        font-size: .9em;
      }

      .card-body {
        .title-text {
          font-size: .8em;
        }
      }
    }

    .card-details {
      left: 5%;
      top: 10%;

      .form-control {
        font-size: .65em;
      }

      .valid-till {
        font-size: .5em;
      }

      select {
        width: 70px;
      }

      select.form-control:not([size]):not([multiple]) {
        height: 22px;
        font-size: .55em;
        padding-left: 1px;
      }
    }

    .order-summary {
      .card-total {
        font-size: .8em;
      }
    }
  }


  .click-button {
    font-size: .9rem;
    font-weight: 400;
    padding: 4px 20px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .payment-container {
    padding: 0 15px;

    .safe-msg {
      margin-top: .2em;
      font-size: .7em;
    }

    .tour-title {
      font-size: .9em;
    }

    .credit-card {
      height: 115%;
    }

    .card-details {
      .cvv {
        width: 50px;
        top: 28%;
        right: 10%;
      }
    }

    .payment-card {

      .card-body {
        .title-text {
          font-size: .8em;
        }
      }
    }
  }
}