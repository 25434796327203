.icon-minus-circle {
  font-size: 1.1rem;
  color: #BDBDBD;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
}

.icon-plus-circle {
  font-size: 1.1rem;
  color: #BDBDBD;
  margin: 0 0 0 0.5rem;
  cursor: pointer;
}

.tb-item-photo img {
  width: 40%;
  height: auto;
}

.table {
  &.item-table {
    th:nth-child(3) {
      width: 120px;
    }

    th:nth-child(5) {
      width: 160px;
    }

    th:nth-child(5), th:nth-child(6) {
      text-align: center;
    }

    td:last-child {
      font-size: 0.9em;
    }
  }

  td, th {
    font-size: 0.9rem;
    color: #424242;
  }
}


.item-table {
  td, th {
    border: none !important;
    border-bottom: 1px solid #dee2e6 !important;
    vertical-align: middle !important;
  }

  //.react-bootstrap-table table {
  //  table-layout: inherit !important;
  //}
}

.react-bootstrap-table-pagination-total {
  font-size: .8em;
  color: #757575;
}

.pagination {
  .page-link {
    color: #455A64;
    font-size: 0.85rem;
  }

  .page-item.active .page-link {
    color: #fff;
    background-color: #81C784;
    border-color: #81C784;
  }

  justify-content: flex-end;
}

.react-bs-table-sizePerPage-dropdown {
  button {
    padding: 5px 15px;
  }

  .dropdown-menu {
    min-width: 1rem;
    padding: 5px 0;
    margin-right: 1rem;

    a {
      font-weight: 600;
    }
  }
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  margin-right: 1rem;
  font-size: 0.8rem !important;
}

.dropdown-menu {
  font-size: 0.8rem;
}