// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer {
  width: 100%;
  height: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #424242;
}

.footer-navlink {
  .nav-link {
    color: #BDBDBD;
    font-size: 14px;
  }

  .nav-link:hover {
    color: #BDBDBD;
  }
}

.copyright-text {
  color: #BDBDBD;
  font-size: 14px;
  margin-top: 15px;
}

.fa-copyright {
  color: #BDBDBD;
}

.softvessel-logo {
  position: relative;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  img {
    width: 150px;
    height: auto;
  }
}

.footer-address {
  position: relative;
  text-align: center;
  p {
    color: #BDBDBD;
    font-size: 14px;
  }
}

.icon-facebook {
  position: relative;
  color: #BDBDBD;
  text-align: center;
  margin-right: 10px;
}

.icon-twitter {
  position: relative;
  color: #BDBDBD;
  text-align: center;
  margin-right: 10px;
}

.icon-instagram {
  position: relative;
  color: #BDBDBD;
  text-align: center;
  margin-right: 10px;
}