.search-box {
  position: relative;
  margin-bottom: 2rem;
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
  .form-control {
    position: relative;
    top: 1px;
    width: 400px;
    height: 40px;
    //margin: auto;
    font-size: 1rem;
    border-radius: 1rem;
    padding-left: 3rem;
    z-index: 1;
  }
  .form-control:hover{
    border: 1px solid var(--primary-color);
    box-shadow: 0 1px 4px 0 rgba(32,33,36,0.28);
    -webkit-appearance:none;
  }
  .form-control:active{
    outline: none !important;
  }
}

.search-box .search-icon {
  .icon-search {
    position: absolute;
    color: #9AA0A6;
    font-size: 1.5rem;
    margin-top: 11px;
    left: 17.8rem;
    z-index: 49;
  }
}

@media only screen and (max-width: 600px) {
  .search-box {
    .icon-search {
      left: 2rem;
      margin-top: 8px;
    }
    .form-control {
      height: 35px;
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .search-box {
    .icon-search {
      left: 11rem;
      margin-top: 8px;
    }
    .form-control {
      height: 35px;
      font-size: 1rem;
    }
  }
}