.delivery-icon svg {
  display: inline;
  color: var(--primary-color);
}

.checkout-btn {
  .btn-primary {
    position: absolute;
    //right: 8rem;
    top: 0.8rem;
    color: #ffffff;
    background-color: var(--primary-color);
    margin-left: 1rem;
    border-color: var(--primary-color);
    justify-content: flex-end !important;
    flex-grow: 0 !important;
  }
  .btn-primary:hover {
    color: #ffffff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  .btn-primary:focus {
    color: #ffffff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
}

.icon-shopping-cart {
  position: absolute;
  display: flex;
  font-size: 2rem;
  color: var(--primary-color);
  margin-left: -14px;
  justify-content: flex-end;
}

.cart-count {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background-color: var(--primary-color);
  position: absolute;
  color: #fff;
  //border: 1px solid black;
  padding-top: .3em;
  box-shadow: 0px 1px 1px 1px #757575;
  font-size: 0.7rem;
  font-weight: bold;
  text-align: center;
  margin-top: -8px;
  z-index: 2;
}

.dropdown-btn-icon {
  .btn-success {
    color:#fff;
    background-color:#28a74500;
    border-color:#28a74500;
    outline: none !important;
    box-shadow: none;
    border:none !important;
  }
  .btn-success:hover {
    color:#fff;
    background-color:#28a74500;
    border-color:#28a74500
  }
  .btn-success.focus,
  .btn-success:focus {
    color:#fff;
    background-color:#28a74500;
    border-color:#28a74500;
    box-shadow:none;
    border:none !important;
  }
  .btn-success.disabled,
  .btn-success:disabled {
    color:#fff;
    background-color:#28a74500;
    border-color:#28a74500;
    border:none !important;
  }
  .btn-success:not(:disabled):not(.disabled).active,
  .btn-success:not(:disabled):not(.disabled):active,
  .show>.btn-success.dropdown-toggle {
    color:#fff;
    background-color:#28a74500;
    border-color:#28a74500;
    border:none !important;
  }
  .btn-success:not(:disabled):not(.disabled).active:focus,
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .show>.btn-success.dropdown-toggle:focus {
    /*! box-shadow:0 0 0 .2rem rgba(72,180,97,.5); */
  }
  .btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
    border:none !important;
  }
  .btn {
    border:none !important;
  }
}

.continue-shopping-btn {
  .shopping-btn {
    position: relative;
    width: 100%;
    border-radius: 10rem;
    right: 0;
    margin-bottom: 10px;
    font-size: 0.8rem;

    &:hover {
      color: #17a2b8;
      background: none;
      //border-color: var(--primary-color);
    }

    &:focus {
      //color: #ffffff;
      background-color: #FAFAFA;
      border-color: #FAFAFA;
    }
  }
}

@media only screen and (max-width: 600px) {
  .checkout-btn {
    display: none;
    margin-right: 100px;
  }
  .btn-success {
    //display: none;
  }
  .cart-count {
    //display: none;
  }
  .icon-shopping-cart {
    //display: none;
  }
}