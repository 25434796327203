.pop-up {
  display: flex;
  justify-content: space-evenly;
  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.modal-content {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
  display: flex !important;
  margin: auto;
}

.welcome-modal{
  .icon-x {
    position: absolute;
    color: #2d3436;
    font-size: 2rem;
    right: 10px;
    top: 10px;
    font-weight: bold;
    cursor: pointer;
  }

  .icon-x:hover {
    color: #000000;
  }
}
