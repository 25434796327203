.cart-dropdown {
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .5rem 1rem;
    right: 13rem;
    top: -3.5rem;
  }
}

.cart-menu {
  .dropdown-menu {
    position: relative;
    background-color: white;
    border-radius: 8px;
    top: 3.5rem;
    left: 2rem;
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.16);
    -moz-box-shadow: 0 0 10px 0 rgba(0,0,0,0.16);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.16);
    max-width: 400px;
  }
  .dropdown-item:hover {
    background-color: white;
  }
  .cart-item img {
    width: 100%;
    height: auto;
  }

  .cart-item-name {
    position: relative;
    color: var(--primary-font-color);
    top: 0.5rem;

    label{
      display: inline-block;
      width: 180%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }

  .cart-item-qty {
    position: relative;
    color: var(--secondary-font-color);
    top: 0.5rem;
  }

  .cart-item-price {
    position: relative;
    display: flex;
    justify-content: right;
    color: var(--primary-color);
    top: 2.5rem;
  }
  .icon-trash {
    position: relative;
    float: right;
    color: #3C6382;
    top: 0.5rem;
    right: 13px;
    cursor: pointer;
  }
  .icon-trash:hover {
    color: #5a6268;
  }
  .cart-item-subtotal-title {
    position: relative;
    color: var(--primary-font-color);
    top: 0.5rem;
  }
  .cart-item-subtotal {
    position: relative;
    display: flex;
    justify-content: right;
    font-weight: 600;
    color: #c82333;
    right: 0.7rem;
    top: 0.5rem;
  }
  .cart-item-discount {
    position: relative;
    display: flex;
    justify-content: right;
    font-weight: 600;
    color: #616161;
    right: 0.7rem;
    top: 0.5rem;
  }
}

.cart-height {
  max-height: 40vh;
  overflow-y: auto;
}

.cart-menu .empty-cart {
  position: relative;
  text-align: center;
  img {
    width: 100px;
    height: auto;
  }
  p {
    color: #c82333;
    margin-bottom: 2px;
  }
  label {
    color: #5a6268;
  }
}

.cart-menu .empty-cart {
  position: relative;
  text-align: center;
  img {
    width: 100px;
    height: auto;
  }
  p {
    color: #c82333;
    margin-bottom: 2px;
  }
  label {
    color: #5a6268;
  }
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}