.payment-method-main-text {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  h5 {
    font-size: 1rem;
  }
}

.credit-card-bg {
  //position: relative;
  width: 50%;
  height: auto;
  text-align: center;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #cce5ff;
  cursor: pointer;
}

.credit-card-bg:active {
  background-color: #F1F8E9;
  border: solid 2px var(--primary-color);
}

.fa-credit-card {
  font-size: 6rem;
  margin: 1rem 1rem auto 1rem;
}

.fa-money-bill-alt {
  font-size: 6rem;
  margin: 1rem 1rem auto 1rem;
}

.payment-label-name {
  position: relative;
}

.order-btn {
  .btn-primary {
    display: flex;
    justify-content: center;
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    width: 8rem;
    margin: 1rem auto auto auto;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  .btn-primary:active {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  .btn-primary:focus {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
}

.order-btn {
  a:hover {
    text-decoration: none;
  }
}