.navbar-text {
  .navbar-light .navbar-nav .nav-link {
    color: #212121;
    font-size: 0.5rem !important;
    //align-content: end;
    //margin-left: 1rem;
  }
}

.header-register-btn {
  .btn-primary {
    color: var(--primary-color);
    background-color: #ffffff;
    border-color: var(--primary-color);
    margin-left: 2rem;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-top: 5px;
  }
  .btn-primary:hover {
    color: var(--primary-color);
    background-color: #ffffff;
    border-color: var(--primary-color);
  }
  .btn-primary:focus {
    color: var(--primary-color);
    background-color: #ffffff;
    border-color: var(--primary-color);
  }
}

.header-login-btn {
  position: relative;
  top: 0.2rem;
  margin-left: 1rem;
  a {
    color: var(--primary-color);
    text-decoration: none;
    background-color: transparent;
    font-size: 1rem;
  }

  a:hover {
    color: var(--primary-color);
    text-decoration: none;
  }
}

.divider{
  position: relative;
  border-top: 1px solid #E0E0E0;
}

.navbar-brand {
  display: inline-block;
  color: var(--primary-color);
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  line-height: inherit;
  white-space: nowrap;
  margin-top: 0.5rem;
  justify-content: end;
  align-content: end;
  font-size: 2rem !important;
  font-weight: bold;
  left: 0;
}

.navbar-brand:hover {
  color: var(--primary-color);
}

.header-bg {
  width: 100%;
  height: 70px;
  //position: fixed;
  padding-top: 1px;
  padding-bottom: 1px;
  -webkit-box-shadow: 0 3px 5px 0 rgba(0,0,0,0.13);
  -moz-box-shadow: 0 3px 5px 0 rgba(0,0,0,0.13);
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.13);
  background: #fff;
  z-index: 50;
  //scroll-padding-top: 300px;
}

.fa-truck {
  display: inline;
  margin-left: 40px;
  margin-top: 7px;
  margin-right: 3px;
}

.icon-phone {
  display: inline;
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 3px;
}

.icon-user {
  display: inline;
  margin-left: 40px;
  margin-top: 8px;
  margin-right: 3px;
}

.navbar {
  height: 2.5rem;
}

.nav-link {
  color:#262626;
  text-decoration:none;
  font-size: 0.9rem;
}

.nav-link:hover {
  color:#262626;
}

.nav {
  margin-left: 0;
}

.navbar-collapse, .navbar {
  justify-content: flex-end !important;
  flex-grow: 0 !important;
}

.top-header-bg {
  //position: fixed;
  //background-color: #fff;
  z-index: 50;
  //padding: 30px;
}

.fixed-header {
  position: fixed;
  width: 100%;
  background-color: white;
  overflow: auto;
  z-index: 99;
}

@media only screen and (max-width: 600px) {
  .navbar {
    height: auto;
    left: 0;
  }
  .nav {
    margin-left: 1rem;
  }
  .navbar-brand {
    margin-left: 1rem;
  }
  .header-login-btn {
    margin-left: 1.8rem;
  }
}