.category-title {
  margin: 20px 0 0;
  font-size: .8em;
  font-weight: 600;
}

.banner {
  width: 100%;
  //height: auto;
  margin: 4em 0 0;
}

.product-bg {
  position: relative;
  //display: flex;
  //justify-content: center;
  width: 100%;
  height: auto;
  min-height: 15em;
  padding: 0.8rem 0.5rem 0.5rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #BDBDBD;
  border-radius: 7px;
  margin-top: 1rem;
  cursor: pointer;
}

.product-img {
  text-align: center;
  //padding: 3em 0;
  img {
    position: relative;
    //display: flex;
    //justify-content: center;
    width: auto;
    max-height: 130px;
    cursor: pointer;
  }

}

.product-title p {
  position: relative;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  min-height: 3em;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

.prices {
  //display: flex;
  //justify-content: space-evenly;
  margin: .8em 0 .5em 0;
}

.product-price {
  position: relative;
  //display: flex;
  //justify-content: space-between;
  color: var(--primary-color);
  font-size: 1.1rem;
  text-align: center;
  font-weight: 600;
  //padding: 10px;
  //margin-top: 0.5rem;
}

.product-price-discount {
  position: relative;
  color: #757575;
  font-size: 1.1rem;
  text-align: left;
  //margin-left: 0.1rem;
  //bottom: -0.21rem;
  //margin-top: 0.5rem;
  //margin-bottom: 0.5rem;
  text-decoration: line-through;
}

.add-to-cart-btn {
  .add-btn {
    position: relative;
    width: 100%;
    color: #ffffff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: 3rem;
    max-width: 115px;
    font-size: .8em;

    &:hover {
      color: #ffffff;
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }

    &:focus {
      color: #ffffff;
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  .update-btn {
    position: relative;
    width: 100%;
    border-radius: 3rem;

    &:hover {
      color: #17a2b8;
      background: none;
      //border-color: var(--primary-color);
    }

    &:focus {
      //color: #ffffff;
      background-color: #FAFAFA;
      border-color: #FAFAFA;
    }
  }
}

.small-cents {
  font-size: .8em;
}

.banner1 {
  position: relative;
  margin-top: 2rem;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.contact-banner {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 1em;
  cursor: pointer;
  border: 1px solid #E0E0E0;
  border-radius: 10px;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  p {
    font-size: 0.8rem;
    text-align: right;
    padding-right: 70px;
  }
}

.contact-banner.checkout-banner {
  margin-top: 0;
}

.product-alert{
  font-weight: 500;
  font-size: .7em;
  font-style: italic;
}

@media only screen and (max-width: 576px) {

  .product-img img {
    width: 100%;
    min-height: 120px;

    //max-height: 130px;
  }
  .product-price {
    font-size: 0.9rem;
  }
  .product-price-discount {
    font-size: 0.8rem;
  }
}