.our-product-txt h1 {
  position: relative;
  color: var(--primary-color);
  text-align: center;
  font-size: 2rem;
}

.cat-box {
  position: relative;
  //display: flex;
  //flex-grow: 1;
  width: 100%;
  justify-content: center;
  height: auto;
  //padding: 2rem 1rem 1rem 2rem;
  background-color: #ffffff;
  border: 1px solid #BDBDBD;
  border-radius: 10px;
  cursor: pointer;
}

.active.cat-box {
  background-color: #F1F8E9;
  border: solid 2px var(--primary-color);
}

.cat-box-active:active {
  background-color: #DCEDC8;
}

.cat-box:focus {
  background-color: #1e7e34;
}

.cat-img {
  position: relative;
  text-align: center;
  padding-top: 20px;
  img {
    width: 70%;
    height: 70%;
    //height: auto;
  }
  p {
    text-align: center;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 576px) {
  .cat-img {
    img {
      width: 100%;
      height: auto;
    }
  }
  .our-product-txt{
    h1{
      font-size: 1em;
    }
  }
  .cat-box {
    padding-left: 5px !important;
    padding-right: 5px !important;
    p{
      font-size: .5em;
    }
  }

}